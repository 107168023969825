$tableColor: grey;

.edit-rental-periods-component {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .header {
    font-weight: bold;
    padding: 0;
    margin: 0;
    font-size: 18px;
  }

  .action-area {
    align-self: flex-end;
  }

  .content {
    border: 1px solid $tableColor;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    flex-direction: column;

    .header {
      font-size: 14px;
      font-weight: bold;
      .content-data {
        padding: 6px 7px;
        padding-right: 3px;
      }
    }

    .content-line {
      &:not(:last-child) {
        border-bottom: 1px solid $tableColor;
      }
      display: flex;
    }
    .content-data {
      &:not(:last-child) {
        border-right: 1px solid $tableColor;
      }
      padding: 2px 5px;
      display: flex;
      align-items: center;
    }

    .time-field {
      width: 9em;
    }

    .comment-field {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .actions-field {
      width: 50px;
      display: flex;
      justify-content: flex-end;
    }

    .no-content {
      font-style: italic;
      text-align: center;
      padding: 10px 0;
    }
  }
}
