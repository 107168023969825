@import "../../shared/constants.scss";

.unavailability-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  margin-top: 3vh;


  .unavailability-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
  }

  .day-header {
    color: #474747;
    font-weight: bold;
    font-size: 22px;
    padding-left: 12px;
    padding-top: 5px;
    border-bottom: 2px solid black;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .header {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 5px;
  }

  .unavailability-header {
    padding: 10px;
    background-color: $secondary;
  }

  .font-size-18 {
    font-size: 18px;
  }
  .font-size-16 {
    font-size: 16px;
  }

  .unavailability-empty {
    text-align: center;
    padding: 40px;
    font-size: 24px;
  }

  .line-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: inline;
    }
    .right {
      display: inline;
    }
  }
  .number-link {
    color: black;
    font-size: 16px;
  }
  .vehicle-font {
    font-weight: 100;
    font-size: 16px;
  }

  .vehicle-driver-name {
    font-size: 16px;
  }

  .edit-buttons {
    display: flex;
    padding-top: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
