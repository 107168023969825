@import "../../shared/constants.scss";

.send-buttons {
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.form {
  gap: 30px;
}
