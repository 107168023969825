@import "../../shared/constants.scss";

.page-content-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  margin-top: 3vh;

  .page-content-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
  }
}
