@import "../../shared/constants.scss";

.list-rental-vehicles-component {

  .rental-vehicle-search-bar {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
  }

  .rental-vehicle-list-container {
    display: flex;
    flex-direction: column;
  }

  .no-results {
    font-style: italic;
    padding-top: 20px;
    text-align: center;
  }
}
