.align-icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.PlannedColor {
    color: orange;
}
.SentColor {
    color: blue;
}
.StartedColor {
    color: green;
}
.FinishedColor {
    color: rgb(77, 73, 73);
}
.CancelledColor {
    color: lightgray;
}
.UndeliveredColor {
    color: red;
}
.CreatedColor {
    color: rgb(140, 240, 218);
}
