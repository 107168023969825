@import "../../shared/constants.scss";

.transportplanlegger-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .full-calendar-wrapper {
    display: flex;
    flex-grow: 1;
    .calendar-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 10px;
    }
    .has-assignments > .fc-datagrid-cell-frame {
      background-color: rgb(250, 249, 233);
    }
    .calendar-inner {
      flex: 1;

      .fc-datagrid-cell-cushion {
        width: 100%;
      }
      .MuiIconButton-root {
        padding: 0;
      }
    }

    .vehicle-item-wrapper {
      .vehicle-line-wrapper {
        display: flex;
        * {
          margin-right: 4px;
        }
        svg {
          padding: 0;
          margin: 0;
          padding-top: 2px;
          margin-right: 2px;
        }
        .vehicle-type {
          padding-right: 10px;
          color: $primary;
          padding-top: 2px;
        }
        .vehicle-driver-name {
        }
        .vehicle-driver-number {
          .number-link {
            color: black;
          }
        }
      }
    }

    .order-info {
      .MuiCard-root {
        padding: 5px 10px;
      }
      .info-item {
        display: flex;
        align-items: center;
        padding-top: 5px;
      }
      .info-label {
        width: 130px;
        color: grey;
        font-size: 0.8em;
      }
      .info-content {
        flex: 1;
        font-size: 0.9em;
        display: flex;
        align-items: center;
      }
      .info-content-bold {
        flex: 1;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        font-weight: bold;
      }
      .status {
        display: flex;
        align-items: center;
        font-size: 0.8em;
        svg {
          font-size: 1em;
          padding-right: 2px;
        }
      }
      .margin-left {
        margin-left: 5px;
      }
    }

    .tasks-wrapper {
      width: 400px;
      height: calc(100vh - 64px);
      background-color: rgb(209, 209, 209);
      box-shadow: -0px 4px 10px 2px rgba(0, 0, 0, 0.4);
      z-index: 1100;

      #external-events {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .day-header {
        color: #474747;
        font-weight: bold;
        font-size: 20px;
        padding-left: 12px;
        padding-top: 5px;
        &:first-letter {
          text-transform: uppercase;
        }
      }

      .orders-content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
      }

      .orders-header {
        padding: 10px;
        background-color: $secondary;
        min-height: 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .title-wrapper {
          display: flex;

          .MuiButton-root {
            min-width: unset;
          }
        }

        .title {
          color: $primary;
          font-size: 22px;
          font-weight: bold;
          flex: 1;
        }
        .filters {
        }
      }

      .task-item {
        margin: 10px;

        .MuiCardActionArea-root {
          padding: 5px 10px;
        }

        .line-wrapper {
          display: flex;
          justify-content: space-between;
        }

        .header {
          color: $primary;
          font-weight: bold;
          font-size: 17px;
          padding-bottom: 0;
        }

        .subtitle {
          padding-bottom: 5px;
          font-size: 15px;
        }

        .footer {
          display: flex;
          padding-bottom: 5px;
          font-size: 15px;

          .left {
            flex: 1;
            font-size: 12px;
            padding-top: 2px;
            svg {
              font-size: 12px;
              padding-right: 4px;
            }
          }

          .right {
            display: flex;
            .fraction {
              padding-right: 5px;
              display: flex;
              align-items: center;
            }
            svg {
              font-size: 16px;
              padding: 0;
              margin: 0;
              padding-left: 5px;
              margin-top: 2px;
            }
          }
        }
      }
      .task-item-undelivered {
        .MuiCard-root {
          background-color: rgba($NotDeliveredColor, 0.3);
        }
      }
    }

    .fc-toolbar-title {
      padding-left: 20px;
    }
  }

  .single-event-draggable {
    svg {
      transform: translateX(-10px);
    }
  }

  .order-comment {
    border: 1px solid grey;
    min-height: 60px;
    background-color: rgb(247, 247, 247);
    padding-left: 5px;
    padding-right: 5px;
  }

  .vehicle-list-wrapper {
    border: 1px solid grey;
    border-radius: 4px;
    padding: 8px;
    margin-top: 4px;
    background-color: #d1d1d1;
  }
  .vehicle-list-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .vehicle-list-assignments-wrapper {
    border: 1px solid grey;
    border-radius: 4px;
    padding: 5px;
    margin-top: 4px;
  }

  .vehicle-list-assignments-item {
    gap: 15px;
    &.head {
      border-bottom: 1px solid grey;
    }
    display: flex;
    > .date {
      flex: 1;
    }
    > .amount {
      flex: 1;
    }
    > .date-field {
      flex: 2;
    }
    > .status {
      flex: 2;
    }
  }

  .orders-filter-partial {
    .MuiAutocomplete-tag {
      max-width: calc(100% - 75px);
    }
  }
}

.underline {
  border-bottom: 1px solid gray;
}

.flexwrap {
  display: flex;
  flex-wrap: wrap;
}
// Intentionally outside so styles are
// applied while dragging

.single-event-draggable-parent {
  padding: 5px 0;
  .MuiCard-root {
    background-color: $primary;
    color: white;
  }
}

.single-event-draggable {
  display: flex;
  align-items: center;
  flex: 1;
  span {
    margin-left: -8px;
  }

  svg {
    transform: translateX(-5px);
  }
}

.buttons-to-left {
  margin-right: auto !important;
}

.align-status {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: inline-flex;
    gap: 18px;
  }
  .right {
    display: inline-flex;
    margin-right: 20px;
    gap: 18px;
  }
}

.contactPerson {
  position: absolute;
  left: 15px;
}

.font-size-15 {
  font-size: 15px;
}
.font-size-17 {
  font-size: 17px;
}

.bold-text {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.text-align-end {
  text-align: end;
}

.inline-substitute {
  padding-left: 18px;
}

.linebreak {
  white-space: pre-line;
}

.shorten-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.right-aligned-vehicle {
  justify-content: space-between;
}

.vehicle-font {
  font-weight: 100;
}

.updated-by {
  color: grey;
  font-size: 0.8em;
  flex: 1;
  display: flex;
  align-items: center;
}

.strike-text {
  text-decoration: line-through;
}

.fc-approveTodayButton-button.fc-approveTodayButton-button {
  background-color: $primary;
  border-color: $primary;
  &:hover {
    background-color: $primaryDark;
    border-color: $primaryDark;
  }
}

.loading .fc-approveTodayButton-button.fc-approveTodayButton-button {
  background-color: rgb(44, 62, 80);
  border-color: rgb(44, 62, 80);
  opacity: 0.65;
  cursor: wait;
}

.fc-timeline-event {
  cursor: pointer;
}

.fc-timeline-body {
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}

.loading .fc-timeline-body {
  background-color: rgb(240, 240, 240);
}

.important-deadline {
  font-size: 20px;
  font-weight: bold;
  color: black;
  padding: 5px 20px;
}

.important-deadline-small {
  font-weight: bold;
  color: red;
  padding: 0px 10px;
  align-self: center;
}

.important-deadline-orderinfo {
  font-weight: bold;
  color: red;
  font-size: 14px;
  align-self: center;
}

.flex-gap-5 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.no-overflow {
  overflow: hidden;
  white-space: nowrap;
}

.timeline-header-component {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  height: 40px;
  align-items: center;

  .align-left {
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .date-text {
    border: none;
    padding-left: 10px;
    min-width: 152px;
    width: 25%;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  .formcontrol-flex-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .vehicle-filter-partial {
    width: 12%;
    padding-right: 40px;
  }

  .vehicle-form {
    width: 100%;

    .MuiOutlinedInput-root {
      max-height: 40px;
    }
  }

  .assignment-filter-partial {
    width: 40%;
    padding-right: 20px;
  }

  .width33 {
    width: 33%;

    .MuiOutlinedInput-root {
      max-height: 40px;
    }
  }

  .filter-display-line {
    display: flex;
    white-space: nowrap;
    // max-height: 40px;
    width: 100%;
    padding-top: 3px;
  }

  .filter-display-width {
    flex: 1;
  }

  .clear-background {
    background-color: red;
  }
}
.unavailability-title {
  min-width: 100px;
  color: #666666;
  font-size: 14px;
}
.unavailability-content {
  flex: 1;
  color: #111111;
  font-size: 16px;
}

.line-through {
  text-decoration: line-through;
}

.rental-modal-content-wrapper {
  min-height: 500px;
}

// Fix ghost event
.fc-media-screen > .fc-event.fc-event-draggable.fc-unselectable.fc-event-dragging {
  opacity: 0;
}
