.align-icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.active-color {
    color: green;
}
.inactive-color {
    color: red;
}
