@import "../../shared/constants.scss";

.my-assignments-page {
  transition: background-color 0.3s;
  max-width: 100%;

  .assignments-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-top: 3vh;
  }

  .assignments-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 760px;
    align-items: center;
  }

  .day-switcher {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .MuiButton-root {
      font-weight: bold;
    }
    .day-text {
      border: none;
      font-size: 24px;
      background-color: #00000000;
      color: #3a3a3a;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      &:active,
      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .assignment-card {
    width: 100%;
    cursor: pointer;
  }

  .assignments-list {
    margin-top: 20px;
    padding: 10px 20px;
    min-height: 69px;
    background-color: #eeeeee;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }

  .assignment-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    .info-line {
      display: flex;
      justify-content: space-between;
    }
  }

  .status-partial {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .align-right {
    display: flex;
    gap: 10px;
  }

  .order-type {
    display: flex;
    color: $primary;
    border: 2px solid $primary;
    font-weight: 600;
    font-size: 16px;
    height: 24px;
    padding: 0 8px;
    text-align: center;
    border-radius: 14px;
    white-space: nowrap;
  }

  .assignment-title {
    color: $primary;
    font-weight: bold;
    font-size: 20px;
  }

  .assignment-header {
    color: $primary;
    font-size: 22px;
    font-weight: bold;
  }

  .assignment-time {
    font-weight: bold;
    color: #3a3a3a;
  }

  .assignment-load {
    color: #3a3a3a;
    width: 1px; // Fixes overflow somehow
  }

  .project-name {
    color: #3a3a3a;
  }

  .control-area {
    position: relative;
    width: 100%;
    .right-icon {
      position: absolute;
      top: 0px;
      right: -20px;
      .MuiButton-root {
        padding: 0;
        margin: 0;
        min-width: 0;
      }
    }
  }

  .no-content {
    color: #3a3a3a;
    padding: 20px 0;
    font-style: italic;
  }

  .assignment-details {
    display: flex;
    justify-content: center;
    .assignment-details-inner {
      max-width: 800px;
      margin: 0 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .toolbar-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 3vh;
    .MuiButton-root {
      padding: 0;
      margin: 0;
      min-width: 0;
    }
  }

  .assignment-header-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .data-component {
    display: flex;
    &.text-above {
      flex-direction: column;
      .data-title {
        padding-bottom: 5px;
      }
    }
    .data-title {
      min-width: 100px;
      color: #666666;
      font-size: 14px;
    }
    .data-content {
      flex: 1;
      color: #111111;
      font-size: 16px;
    }
  }

  .text-field {
    border: 1px solid rgb(180, 180, 180);
    padding: 5px;
    min-height: 80px;
    border-radius: 4px;
  }

  .last-changed {
    font-style: italic;
    padding-top: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .my-assignments-page.list {
    background-color: #eeeeee;
    .control-area .right-icon {
      right: -10px;
    }
  }
}
