.landing-page {
  .overview-wrapper {
    padding-top: 20vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page-wrapper {
    padding: 10px;
  } 
}
