.expand-item {
    cursor: pointer;
    max-height: 0;
    opacity: 0;
    animation: grow 0.4s ease-in-out forwards;
    -webkit-animation: grow 0.4s ease-in-out forwards;
    transition: background-color 0.2s;
  }
  
  @-webkit-keyframes grow {
    to {
      max-height: 50px;
      opacity: 1;
    }
  }
  @keyframes grow {
    to {
      max-height: 50px;
      opacity: 1;
    }
  }