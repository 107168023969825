.create-rental-period-content-component {
  margin-top: 10px;

  .send-buttons {
    display: flex;
    padding-top: 20px;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }

  .form {
    gap: 20px;
  }
}
