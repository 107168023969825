@import "../../shared/constants.scss";

.edit-rental-vehicle-component {
  .edit-buttons {
    width: 100%;
    display: flex;
    padding-top: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .status-wrapper {
    padding-top: 20px;
    align-self: flex-end;
  }
}
