@import '../../shared/constants.scss';

// using selector twice to override MUI style
.UnderPlanningBackgroundColor.UnderPlanningBackgroundColor {
  background-color: $UnderPlanningColor;
}

.ApprovedBackgroundColor.ApprovedBackgroundColor {
  background-color: $ApprovedColor;
}

.StartedBackgroundColor.StartedBackgroundColor {
  background-color: $StartedColor;
}

.CompletedBackgroundColor.CompletedBackgroundColor {
  background-color: $CompletedColor;
}

.CancelledBackgroundColor.CancelledBackgroundColor {
  background-color: $CancelledColor;
  color: rgb(0, 0, 0);
}

.NotDeliveredBackgroundColor.NotDeliveredBackgroundColor {
  background-color: $NotDeliveredColor;
}

.DeletedBackgroundColor.DeletedBackgroundColor {
  background-color: $DeletedColor;
}

.UnderPlanningBackgroundColor.UnderPlanningBackgroundColor:hover {
  background-color: $UnderPlanningColor;
}

.ApprovedBackgroundColor.ApprovedBackgroundColor:hover {
  background-color: $ApprovedColor;
}

.StartedBackgroundColor.StartedBackgroundColor:hover {
  background-color: $StartedColor;
}

.CompletedBackgroundColor.CompletedBackgroundColor:hover {
  background-color: $CompletedColor;
}

.CancelledBackgroundColor.CancelledBackgroundColor:hover {
  background-color: $CancelledColor;
}

.NotDeliveredBackgroundColor.NotDeliveredBackgroundColor:hover {
  background-color: $NotDeliveredColor;
}

.DeletedBackgroundColor.DeletedBackgroundColor:hover {
  background-color: $DeletedColor;
}