@import "../../shared/constants.scss";

  .unavailability-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-top: 1vh;

  .unavailability-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;

    .header {
      display: flex;
      gap: 10px;

      .header-group {
        flex: 1;
      }
    }
  }

  .header {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
  }

  .subs-header {
    padding: 10px;
    background-color: $secondary;
  }

  .subs-empty {
    text-align: center;
    padding: 40px;
    font-size: 24px;
  }

  .line-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: inline;
    }
    .right {
      display: inline;
    }
  }

  .sub-name {
    font-size: 16px;
    font-weight: bold;
  }

  .sub-number {
    font-weight: bold;
    text-decoration: underline;
    color: black;
    font-size: 16px;
  }

  .sub-period {
    padding-top: 7px;
    font-size: 14px;
  }

  .transform-icon {
    transform: translateY(4px);
  }

  .send-buttons {
      display: flex;
      padding-top: 20px;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
    }

  .close-button {
    align-self: flex-end;
    padding-top: 20px;
  }
}

