.rental-vehicle-list-page {
  .rental-vehicle-area-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-top: 3vh;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .project-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
  }

  .project-area-item {
    .MuiCardContent-root {
      padding: 10px 15px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 16px;
    }
  }

  .project-number {
    margin-right: 10px;
  }

  .rental-vehicle-search-bar {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
  }
  .rental-vehicle-list-container {
    display: flex;
    flex-direction: column;
  }

  .flexparentme {
    display: flex;
    align-items: center;
  }
  .flexme {
    flex: 1;
  }

  .padleft {
    padding-left: 10px;
  }

  .star {
    svg {
      transform: translateY(2px);
      color: #f6ba00;
    }
    &.loading {
      svg {
        transform: translateY(0);
        font-size: 12px;
        width: 20px;
      }
    }
  }

  .last-used {
    font-style: italic;
    font-size: 12px;
    padding-right: 4px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .no-results {
    font-style: italic;
    padding-top: 20px;
    text-align: center;
  }
}
